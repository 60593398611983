import React from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import styled, { ThemeProvider } from 'styled-components';
import Img from 'gatsby-image';
import { cwTheme } from '../theme/theme.js';
import SignUpForm from '../components/SignUpForm';
import Button from '../components/Button';
import LiveVideo from '../components/LiveVideo';

const PostSection = styled.section`
	padding: .5rem 0;
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		padding: 2rem 0;
	}
`;

const MetaWrapper = styled.div`
	display: flex;
	max-width: 75em;
	margin: 0 auto;

	width: 100%;
	flex-direction: column;
	justify-content: center;
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		flex-direction: row;
	}
`;

const PostDescription = styled.div`
	flex: 7;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const PostWrapper = styled.div`
	padding: 1rem;
	max-width: 600px;
	margin: 0 auto;
	width: 100%;
	box-sizing: border-box;
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		padding: 1.5rem 1rem;
	}
`;

const PostContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	box-sizing: content-box;
`;

const Post = styled.div``;

const PostTitle = styled.h3`
	
	line-height: 1.2em;
	margin-top 0em;
	margin-bottom: .25em;

	
`;

const PostBody = styled.div`
	width: 100%;
	font-family: ${cwTheme.sansSerif} p {
		font-family: 'Merriweather', serif;
		font-weight: 400;
	}

	img {
		max-width: 100%;
		margin: 2rem 0;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: ${cwTheme.sansSerif};
	}
`;

const PostVideo = styled.div`
	width: 100%;
	// background-color: #f3f3f3;

	display: flex;
	justify-content: center;
	flex-direction: column;

	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		flex-direction: row;
	}
`;

const VideoWrapper = styled.div`
	max-width: 750px;
	margin: 0 auto;
	width: 100%;
	@media (min-width: ${cwTheme.xLargeBreakPoint}) {
		max-width: 1000px;
	}
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		margin: 0;
	}
`;

const ChatContainer = styled.div`
	// display: none;
	// @media (min-width: ${cwTheme.mediumSmallBreakPoint}) {
	// 	display: block;
	// }

	iframe html {
		overflow: auto;
	}
`;

export default ({ data }) => {
	const event = data.contentfulPrivateEvent;

	const { title, description } = data.contentfulPrivateEvent;
	return (
		<ThemeProvider theme={{ mode: 'light' }}>
			<Layout>
				<SEO title={title} description={description.childMarkdownRemark.excerpt} />

				<PostSection>
					<PostContainer>
						<Post>
							<PostVideo>
								<VideoWrapper>
									<LiveVideo videoSrcUrl={event.videoEmbedUrl} />
								</VideoWrapper>
								{event.chatEnabled == true ? (
									<ChatContainer>
										<iframe
											src={event.videoEmbedUrl + '/chat/'}
											width="400"
											height="500"
											frameborder="0"
										/>
									</ChatContainer>
								) : (
									''
								)}
							</PostVideo>
							<MetaWrapper>
								<PostDescription>
									<PostWrapper>
										<PostTitle>{title}</PostTitle>
										<PostBody
											dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark.html }}
										/>
									</PostWrapper>
								</PostDescription>
							</MetaWrapper>
						</Post>
					</PostContainer>
				</PostSection>

				<SignUpForm header={'Sign up to receive wellness tips in your inbox!'} />
			</Layout>
		</ThemeProvider>
	);
};

export const query = graphql`
	query($slug: String!) {
		contentfulPrivateEvent(slug: { eq: $slug }) {
			title
			slug
			chatEnabled
			videoEmbedUrl
			description {
				childMarkdownRemark {
					excerpt
					timeToRead
					html
				}
			}
		}
	}
`;
