import React from 'react';
import styled from 'styled-components';
import { cwTheme } from '../theme/theme.js';
import props from 'prop-types';

const CwButton = styled.a`
	padding: .5rem 2rem;
	vertical-align: middle;
	margin-bottom: 1rem;
	display: inline-block;
	transition: .3s;
	border: none;
	border-radius: 2px;
	cursor: pointer;
	text-decoration: none;
	transition: .3s;
	background-color: ${(props) => props.color};
	color: ${(props) => props.textColor};
	&:hover,
	&:focus {
		background-color: ${(props) => props.hoverColor};
		color: ${(props) => props.hoverTextColor};
	}
`;

const Button = (props) => {
	return (
		<CwButton
			style={props.style}
			href={props.link}
			hoverColor={props.hoverColor}
			color={props.color}
			textColor={props.textColor ? props.textColor : cwTheme.white}
			hoverTextColor={props.hoverTextColor ? props.textColor : cwTheme.white}
		>
			{props.children}
		</CwButton>
	);
};

export default Button;
