import React from 'react';
import styled from 'styled-components';
import { cwTheme } from '../theme/theme.js';

const EmbedContainer = styled.div`
	// position: relative;
	// padding-bottom: 56.25%;
	// height: 0;
	// overflow: hidden;
	// max-width: 100%;

	// iframe,
	// object,
	// embed {
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	width: 100%;
	// 	height: 100%;
	// }
`;

const iFrameStyles = {
	position: 'absolute',
	top: '0',
	left: '0',
	width: '100%',
	height: '100%'
};

const LiveVideo = ({ videoSrcUrl, ...props }) => (
	<EmbedContainer>
		<div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
			<iframe
				src={videoSrcUrl + '/embed/'}
				frameborder="0"
				allow="autoplay; fullscreen"
				allowfullscreen
				style={iFrameStyles}
				referrerPolicy="origin"
			/>
		</div>
	</EmbedContainer>
);
export default LiveVideo;
